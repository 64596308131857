<template>
    <b-card>
        <b-form v-on:submit.prevent>
            <b-row>
                <b-col cols="12">
                    <b-form-group
                    label="Roles Name"
                    label-for="rolesName"
                    label-cols-md="2"
                    >
                        <b-form-input
                            id="rolesName"
                            placeholder="Roles Name"
                            v-model="form.roles_name"
                            required
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <!-- PERMISSION TABLE -->
                    <b-card
                    no-body
                    class="border mt-1"
                    >
                        <b-card-header class="p-1">
                            <b-card-title class="font-medium-2">
                            <feather-icon
                                icon="LockIcon"
                                size="18"
                            />
                            <span class="align-middle ml-50">Permission</span>
                            </b-card-title>
                        </b-card-header>
                        <div class="container">
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="User">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Profile
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="27"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="28"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="29"
                                            >
                                            Update Password
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="30"
                                            >
                                            Image Store
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="31"
                                            >
                                            Push Notification
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Role
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="2"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[1, 3, 6]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="4"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="5"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Master">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Position
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="23"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[22, 24]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="25"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="26"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Organization
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="8"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[7, 9]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="10"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="11"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Division
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="13"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[12, 14]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="15"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="16"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Department
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="18"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[17, 19]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="20"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="21"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Status
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="106"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[105, 107]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="108"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="109"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            PTKP
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="101"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[100, 102]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="103"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="104"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Currency
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="125"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[124, 126]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="127"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="128"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Payment Type
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="152"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[151, 153]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="154"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="155"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Shipping Type
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="157"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[156, 158]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="159"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="160"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="HR">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Employee
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[33, 40, 42, 44]"
                                            checked
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[32, 34]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[35, 41, 43, 45]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="36"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="37"
                                            >
                                            Export
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="38"
                                            >
                                            Import
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="39"
                                            >
                                            Image Store
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Attendance
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[48, 49, 54]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="52"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="50"
                                            >
                                            Check In &amp; Out
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="51"
                                            >
                                            Add Manual
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="53"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="55"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Overtime
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="58"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[56, 57, 61]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="59"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="60"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="62"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Permit
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="65"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[63, 64, 68]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="66"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="67"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="69"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Reimbursement
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="72"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[70, 71, 75]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="73"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="74"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="76"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Cash Advance
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="79"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[77, 78, 83]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="81"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[80, 82]"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="84"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Payroll
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[110, 115]"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[111, 114, 116]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[112, 117]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[113, 118]"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Company">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Profile
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="46"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="47"
                                            >
                                            Read
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Product">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Product Unit
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="96"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[95, 97]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="98"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="99"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Product Category
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="91"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[90, 92]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="93"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="94"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Products
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="86"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[85, 87]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="88"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="89"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Procurement">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Vendor Category
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="130"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[129, 131]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="132"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="133"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Vendor
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="120"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[119, 121]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="122"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="123"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Purchase
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[243, 248]"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[242, 244, 247, 249]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[245, 250]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[246, 251]"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Receiving
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="253"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[252, 254]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="255"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="256"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Warehouse">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Warehouse Location
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="135"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[134, 136]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="137"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="138"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Bin Location
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="146"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[144, 145]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="147"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="148"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Item
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="140"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[139, 142]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[141, 143]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Fulfillment
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[214, 220]"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[213, 215, 219, 221]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[216, 222]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[217, 223]"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="218"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Shipping
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="226"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[224, 225]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="229"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[227, 228]"
                                            >
                                            Confirm
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Warehouse Borrow
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[230, 231, 233, 234]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="232"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Stock Opname
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="236"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[235, 237, 276, 278]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[238, 277]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[239, 240]"
                                            >
                                            Confirm
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="241"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Receiving
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[257, 258]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="259"
                                            >
                                            Update
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Transfer
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[261, 267]"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[260, 262, 266, 268]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[263, 269]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="270"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[264, 265]"
                                            >
                                            Confirm
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Replenishment
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="272"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[271, 273]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="274"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="275"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Sales">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Cart
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="149"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="150"
                                            >
                                            Read
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Catalog
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="197"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[196, 198]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="199"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="200"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Customer Category
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="167"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[166, 168]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="169"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="170"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Customer
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="162"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[161, 163]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="164"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="165"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Quotation
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[172, 177]"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[171, 173, 176, 178]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[174, 179]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[175, 180]"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Order
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[182, 187, 193]"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[181, 183, 186, 188, 192, 194]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[184, 189]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[185, 190, 195]"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="191"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Sales Borrow
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[202, 210]"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[201, 203, 209, 211]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[204, 206, 207, 208, 212]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="205"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Task
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="280"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[279, 281]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="282"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="283"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Target
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="285"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[284, 286]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="287"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="288"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Asset">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Category
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="295"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[294, 296]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="297"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="298"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Location
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="300"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[299, 301]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="302"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="303"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Item
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="290"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[289, 291]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="292"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="293"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                        </div>
                    </b-card>
                </b-col>
                <!-- submit and reset -->
                <b-col md="12" class="mt-2">
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="addRoles()"
                    >
                    Submit
                    </b-button>
                    <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    >
                    Reset
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BTable, BCardHeader, BCardTitle, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        ToastificationContent,
        AppCollapse,
        AppCollapseItem,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BListGroupItem,
        BAvatar,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                roles_name: ''
            },
            selected: [],
            permission: [],
            errMessage: ''
        }
    },
    methods: {
        addRoles() {
            let $permissions = this.selected;
            // console.log($permissions);

            var i;
            for(i=0; i < $permissions.length; i++) {

                var $permission = {
                    permission: $permissions[i]
                };
                this.permission.push($permission);
            };
            // console.log(this.permission);

            this.$http
            .post("user/roles/add", {
                "name": this.form.roles_name,
                "permission": this.permission
            })
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success add Role',
                        variant: 'success',
                    },
                })
                location.href = "/user/roles"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
    },
}
</script>